import Axios from 'axios';

import { MOCK_OPEN_CONTRACT } from './mockOpenContract';
import { InventoryItem } from '@/types/Item';
import { CompletedTaskNotificationResponse } from '@/requests/types';
import { UserAccount, UserDrop } from '@/types/User';

export interface CreateContractResponse {
  error?: string;
  user?: Partial<UserAccount>;
  wonDrop?: UserDrop;
  eventCompletedTaskNotification?: CompletedTaskNotificationResponse;
}

export default async function createContract(
  contractItems: number[]
): Promise<CreateContractResponse> {
  if (process.env.NODE_ENV === 'development') {
    return MOCK_OPEN_CONTRACT;
  }

  return (await Axios.post<CreateContractResponse>('/api/contract/create', {
    contractItems
  })).data;
}
