
import { Component, Ref, Vue } from 'vue-property-decorator';

import Breadcrumbs from '../components/Layout/Breadcrumbs.vue';
import Switcher from '../components/Switcher.vue';
import ItemInfo from '../components/Item/Item.vue';
import Button from '../components/Buttons/Button.vue';
import PadButton from '@/components/Buttons/PadButton.vue';
import LoadingScreen from '../components/Layout/LoadingScreen.vue';
import EmptyInventory from '../components/DropInventory/EmptyInventory/EmptyInventory.vue';
import LoginGeneralButton from '@/components/Buttons/LoginGeneralButton.vue';
import TryAgainButton from '@/components/Buttons/TryAgainButton.vue';
import ContractPrizeMenu from '@/components/Contract/ContractPrizeMenu.vue';
import createContractRequest from '@/requests/Contract/createContract';
import sellItem from '@/requests/Withdraw/sellItem/sellItem';
import { getEventNotification } from '@/helpers/getNotification';
import ItemImage from '@/components/Item/ItemImage.vue';
import DropInventory from '@/components/DropInventory/DropInventory.vue';
import { UserDrop } from '@/types/User';
import { getDropSellPrice } from '@/helpers/getDropSellPrice';
import ItemCheckedHover from '@/components/Item/ItemCheckedHover.vue';
import SellDrop from '@/components/SellDrop.vue';

const config = { minItems: 3, minMultiply: 0.25, maxMultiply: 4 };

@Component({
  components: {
    ItemCheckedHover,
    DropInventory,
    ItemImage,
    Breadcrumbs,
    Switcher,
    ItemInfo,
    LoadingScreen,
    EmptyInventory,
    Button,
    PadButton,
    LoginGeneralButton,
    TryAgainButton,
    ContractPrizeMenu,
    SellDrop,
  },
})
export default class Contracts extends Vue {
  contractDrops: UserDrop[] = [];
  blockRequest: boolean = false;
  wonDrop: null | UserDrop = null;
  @Ref('dropInventory') readonly dropInventory: DropInventory;

  created() {
    this.$setPageTitle('Контракты | DOTALOOT');
  }

  async createContract() {
    if (!this.user && !this.blockRequest) return;

    try {
      this.blockRequest = true;

      if (this.contractDrops.length < this.contractConfig.minItems)
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Контракт',
          text: `Добавьтя хотя бы ${this.contractConfig.minItems} вещи для создания контракта.`,
        });

      const contractDropIds = this.contractDrops.map(drop => drop.id);

      const { error, wonDrop, user, eventCompletedTaskNotification } =
        await createContractRequest(contractDropIds);

      if (error) {
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Контракт',
          text: error,
        });
      }

      if (eventCompletedTaskNotification) {
        this.$notify(getEventNotification(eventCompletedTaskNotification));
      }

      contractDropIds.map(this.dropInventory.removeDrop);
      this.contractDrops = [];
      this.wonDrop = wonDrop;

      this.$store.commit('user/update', user || {});
    } catch (e) {
      console.log(e);
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'error',
        title: 'Контракт',
        text: 'Произошла неизвестная ошибка при создании контракта. Пожалуйста, попробуйте позже.',
      });
    } finally {
      this.blockRequest = false;
    }
  }

  cleanContract() {
    this.contractDrops.map(drop => this.dropInventory.toggleDrop(drop.id));
    this.contractDrops = [];
  }

  toggleItem(drop: UserDrop) {
    if (this.wonDrop) return;

    const dropInContractIndex = this.contractDrops.findIndex(
      dropInContract => dropInContract.id === drop.id
    );

    if (dropInContractIndex > -1) {
      this.contractDrops.splice(dropInContractIndex, 1);
      this.dropInventory.toggleDrop(drop.id);
    } else {
      if (this.contractDrops.length >= 10) return;

      this.contractDrops.push(drop);
      this.dropInventory.toggleDrop(drop.id);
    }
  }

  createContractAgain(leaveDrop: boolean = true) {
    if (!this.wonDrop) return;

    if (leaveDrop) {
      this.dropInventory.addDrop(this.wonDrop);
    }

    this.wonDrop = null;
  }

  async sellDrop(id: number) {
    if (!this.user) return;
    if (this.blockRequest) return;
    this.blockRequest = true;

    try {
      const { error, user } = await sellItem(id);

      if (error) {
        return this.$notify({
          group: 'notification',
          duration: 5500,
          type: 'warning',
          title: 'Инвентарь',
          text: error,
        });
      }

      this.$store.commit('user/update', user);
      this.createContractAgain(false);
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'success',
        title: 'Инвентарь',
        text: 'Ваш выигрыш из контракта успешно продан!',
      });
    } catch (e) {
      console.log(e);
      this.$notify({
        group: 'notification',
        duration: 5500,
        type: 'error',
        title: 'Инвентарь',
        text: 'Произошла неизвестная ошибка при продаже предмета, попробуйте позже.',
      });
    } finally {
      this.blockRequest = false;
    }
  }

  openMana() {
    // TODO: handle mana modal
    // ModalModule.toggle_modal({ name: 'mana' });
  }

  get user() {
    return this.$store.getters['user/isAuthorized']
      ? this.$store.state.user
      : null;
  }

  get contractConfig(): typeof config {
    return config;
  }

  get sum() {
    if (!this.user) return 0;
    return this.contractDrops.reduce((a, b) => a + b.item.price, 0);
  }
}
